import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Search from 'app/components/search';
import Pagination from 'app/components/ReactPagination';
import { RefillHistoryHeader } from 'constants/table-header-constants';
import PageLoader from 'app/components/PageLoader';
import CustomTable from '../CustomTable';
import { getUserRefillHistory } from 'app/api/latest/UpdateUserRefill';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Incorrect from 'images/incorrect.png';
import { Col, Form, Row } from 'react-bootstrap';
// Set the app element for react-modal
Modal.setAppElement('#root');

const HistoryContainer = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [errors, setErrors] = useState({
    updatedDateError: '',
    eventDateError: ''
  });
  // Filter states
  const [filterValues, setFilterValues] = useState({
    updatedDateStart: null,
    updatedDateEnd: null,
    eventDateStart: null,
    eventDateEnd: null
  });

  const dataPerPage = 10;

  useEffect(() => {
    getContentDataHandler();
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data?.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler() {
    setPageLoading(true);
    const dataArr = await getUserRefillHistory();

    if (dataArr?.data?.response) {
      setTotalData(dataArr.data.response);
      setData(dataArr.data.response);
      let count = Math.ceil(dataArr.data.response.length / dataPerPage);
      setPageCount(count);
    } else {
      setTotalData([]);
      setData([]);
      setPageCount(1);
    }

    setPageLoading(false);
  }

  function inputChangeHandler(searchVal) {
    setValues(searchVal);

    if (searchVal.trim() === '') {
      setData(totalData);
      return;
    }

    const searchNumbers = searchVal
      .split(',')
      .map((num) => num.trim())
      .filter((num) => num.length > 0);

    const filteredData = totalData.filter((item) => {
      if (!item.phone_number) return false;
      return searchNumbers.some((num) =>
        item.phone_number.toLowerCase().includes(num.toLowerCase())
      );
    });
    setData(filteredData);
  }

  function handleFilterChange(field, value) {
    setFilterValues(prev => ({
      ...prev,
      [field]: value
    }));
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  function sortData(field) {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setData(sortedData);
    setSortBy(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  function applyFilters() {
    if (errors.updatedDateError || errors.eventDateError) {
      return;
    }
    const newErrors = {};
  
  if (filterValues.updatedDateStart > filterValues.updatedDateEnd) {
    newErrors.updatedDateError = "Updated start date must be before end date";
  }

  if (filterValues.eventDateStart > filterValues.eventDateEnd) {
    newErrors.eventDateError = "Event start date must be before end date";
  }

  if (Object.keys(newErrors).length > 0) {
    setErrors((prev) => ({ ...prev, ...newErrors }));
    return;
  }
    let filteredData = [...totalData];
  
    // Filter by updated date range
    if (filterValues.updatedDateStart && filterValues.updatedDateEnd) {
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.created_on);
        const startDate = new Date(filterValues.updatedDateStart);
        // Set end date to end of the day (23:59:59)
        const endDate = new Date(filterValues.updatedDateEnd);
        endDate.setHours(23, 59, 59, 999);
        
        return itemDate >= startDate && itemDate <= endDate;
      });
    }
  
    // Filter by event date range
    if (filterValues.eventDateStart && filterValues.eventDateEnd) {
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.refilled_on);
        const startDate = new Date(filterValues.eventDateStart);
        // Set end date to end of the day (23:59:59)
        const endDate = new Date(filterValues.eventDateEnd);
        endDate.setHours(23, 59, 59, 999);
  
        return itemDate >= startDate && itemDate <= endDate;
      });
    }
  
    setData(filteredData);
    setPageCount(Math.ceil(filteredData.length / dataPerPage));
    setCurrentPage(1);
    setShowFilterModal(false);
  }

  function resetFilters() {
    setFilterValues({
      updatedDateStart: null,
      updatedDateEnd: null,
      eventDateStart: null,
      eventDateEnd: null
    });
    setErrors({
      updatedDateError: '',
      eventDateError: ''
    });
    setData(totalData);
    setShowFilterModal(false);
  }

  let pageMeta = {
    total: data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount,
  };
  useEffect(() => {
    const newErrors = {
      updatedDateError: '',
      eventDateError: ''
    };
  
    // Validate Updated Date range
    if (filterValues.updatedDateStart && filterValues.updatedDateEnd) {
      if (filterValues.updatedDateStart > filterValues.updatedDateEnd) {
        newErrors.updatedDateError = 'Updated start date must be before end date';
      }
    }
  
    // Validate Event Date range
    if (filterValues.eventDateStart && filterValues.eventDateEnd) {
      if (filterValues.eventDateStart > filterValues.eventDateEnd) {
        newErrors.eventDateError = 'Event start date must be before end date';
      }
    }
  
    setErrors(newErrors);
  }, [filterValues]);
  const handleSort = (field, direction) => {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a[field] || 0);
      const dateB = new Date(b[field] || 0);
      return direction === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setData(sortedData);
    // Calculate current page data after sorting
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    currentData = sortedData.slice(indexOfFirstData, indexOfLastData);
  };
  return (
    <>
      <div className="cms-content__container">
        <div className="content-container__actionBox">
          <div className="content-container__filterSearch">
            <Search
              value={values}
              placeholder="Mobile No or CSV file"
              className={""}
              onChange={inputChangeHandler}
            />
          </div>
          {/* <div className="content-container__sortOptions">
            <button className="button" onClick={() => sortData("created_on")}>
              Sort by Updated Date{" "}
              {sortBy === "created_on" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </button>
            <button className="button" onClick={() => sortData("refilled_on")}>
              Sort by Event Date{" "}
              {sortBy === "refilled_on" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </button>
          </div> */}

          <button
            className="button ml-4"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>

        <Modal
          isOpen={showFilterModal}
          onRequestClose={() => setShowFilterModal(false)}
          // style={customStyles}
          contentLabel="Filter Modal"
          className="ArticleModal"
          overlayClassName="ArticleModal__Overlay"
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "white",
              padding: "20px",
              borderRadius: "4px",
              zIndex: 1001,
            },
          }}
        >
          <div className="Filters">
            <div className="Filters_title">Filter</div>
            <img
              onClick={() => setShowFilterModal(false)}
              src={Incorrect}
              alt="Go to Blogs"
              width="29"
              className="Wrong-Icon"
              height="29"
            />
          </div>
          <div className="modal-content">
            {/* <div className="modal-header">
              <h2>Filter</h2>
              <button 
                onClick={() => setShowFilterModal(false)}
                className="close-button"
              >
                ✕
              </button>
            </div> */}

            <div className="modal-body" style={{ width: "50%" }}>
              <div className="filter-section">
                <h4>Updated Date Range</h4>
                <Form.Group>
                  <Row>
                    <Col className="d-flex" style={{ gap: "30px" }}>
                      <Row>
                        <Form.Label>Start Date</Form.Label>
                        <div className="Sample calander-box">
                          <div className="Sample__container">
                            <main className="Sample__container__content">
                              <DateTimePicker
                                onChange={(date) =>
                                  handleFilterChange("updatedDateStart", date)
                                }
                                value={filterValues.updatedDateStart}
                                format="dd-MM-yyyy"
                                clearIcon={null}
                                placeholder="dd-mm-yyyy"
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="yyyy"
                                clearAriaLabel="Clear value"
                              />
                            </main>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Form.Label>End Date</Form.Label>
                        <div className="Sample calander-box">
                          <div className="Sample__container">
                            <main className="Sample__container__content">
                              <DateTimePicker
                                onChange={(date) =>
                                  handleFilterChange("updatedDateEnd", date)
                                }
                                value={filterValues.updatedDateEnd}
                                format="dd-MM-yyyy"
                                clearIcon={null}
                                placeholder="dd-mm-yyyy"
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="yyyy"
                              />
                            </main>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                {errors.updatedDateError && (
                  <div className="text-danger mt-2 small">
                    {errors.updatedDateError}
                  </div>
                )}
              </div>

              <div className="filter-section">
                <h4>Refill Date Range</h4>
                <Form.Group>
                  <Row>
                    <Col className="d-flex" style={{ gap: "30px" }}>
                      <Row>
                        <Form.Label>Start Date</Form.Label>
                        <div className="Sample calander-box">
                          <div className="Sample__container">
                            <main className="Sample__container__content">
                              <DateTimePicker
                                onChange={(date) =>
                                  handleFilterChange("eventDateStart", date)
                                }
                                value={filterValues.eventDateStart}
                                format="dd-MM-yyyy"
                                clearIcon={null}
                                placeholder="dd-mm-yyyy"
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="yyyy"
                                clearAriaLabel="Clear value"
                              />
                            </main>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Form.Label>End Date</Form.Label>
                        <div className="Sample calander-box">
                          <div className="Sample__container">
                            <main className="Sample__container__content">
                              <DateTimePicker
                                onChange={(date) =>
                                  handleFilterChange("eventDateEnd", date)
                                }
                                value={filterValues.eventDateEnd}
                                format="dd-MM-yyyy"
                                clearIcon={null}
                                placeholder="dd-mm-yyyy"
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="yyyy"
                              />
                            </main>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                {errors.eventDateError && (
                  <div className="text-danger mt-2 small">
                    {errors.eventDateError}
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button onClick={applyFilters} className="button">
                Apply filter
              </button>
              <button onClick={resetFilters} className="button">
                Reset filter
              </button>
            </div>
          </div>

          <style jsx>{`
            .modal-content {
              display: flex;
              flex-direction: column;
              min-height: 400px;
            }

            .modal-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 20px;
              border-bottom: 1px solid #eee;
            }

            .modal-header h2 {
              margin: 0;
              font-size: 20px;
              font-weight: 500;
            }

            .close-button {
              background: none;
              border: none;
              font-size: 20px;
              cursor: pointer;
              padding: 5px;
            }

            .modal-body {
              padding: 20px 10px;
              flex-grow: 1;
            }

            .filter-section {
              margin-bottom: 24px;
            }

            .filter-section h4 {
              margin: 0 0 12px 0;
              font-weight: 500;
            }

            .date-inputs {
              display: flex;
              flex-direction: column;
              gap: 12px;
            }

            .date-inputs .react-datetime-picker {
              width: 100%;
            }

            .modal-footer {
              display: flex;
              justify-content: flex-start;
              gap: 12px;
              padding: 20px;
              border-top: 1px solid #eee;
            }
          `}</style>
        </Modal>

        {currentData && currentData?.length > 0 ? (
          <div className="content-container__table">
            <CustomTable
              data={currentData}
              tableHeader={RefillHistoryHeader}
              onSort={handleSort}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center">No Data Found</div>
        )}

        <div className="content-container__pagination">
          <Pagination
            pageMeta={pageMeta}
            initialPage={Number(pageCount)}
            handleChange={paginationHandler}
          />
        </div>
        {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
      </div>
    </>
  );
};

export default HistoryContainer;