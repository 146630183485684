import { useHistory } from "react-router";
import BasePage from "app/pages/BasePage";
import React, { useState, useEffect } from "react";
import NewInput from "app/components/NewInput";
import Dropdown from "app/shared/components/Dropdown";
import { imageSizeValidation } from "app/utils/Parsers";
import Toast from "app/utils/Toast";
import ValidationMessage from "app/utils/ValidationMessage";
import { uplaodMedia } from "app/api/widgets";
import { CloseBlackIcon } from "app/constants/image-constants";
import Button from "app/components/button";
import { useParams } from "react-router-dom";
import NumberInput from "app/components/UI/NumberInput";
import VersionDropDown from "app/components/VersionDropDown";
import { getVersionControl } from "app/api/versionList";
import useTabBarOptions from "app/utils/useTabbarOptions";
import moment from "moment";
import CTAType from "app/components/CTAType";
import {
  createGamificationReward,
  getGamificatinRewardsById,
  getLocalasationRewardByid,
  updateGamificationRewards,
  updateLocalisationGamificationRewards
} from "app/api/gamificationReward";
import TabBar from "app/components/NewDesign/TabBar";
import Calender from "app/components/Calender";
import DropdownOptions from "app/utils/DropdownOptions";
const FORM_LABELS = {
  REWARD_TITLE: "Reward Title",
  CATEGORY: "Reward Category",
  OFFER_TYPE: "Reward Offer Type",
  OFFER: "Reward Offer",
  COIN_VALUE: "Coin Value",
  REWARD_BANNER_HUB: "Reward Banner Hub",
  REWARD_BANNER_MODAL: "Reward Banner Modal",
  REWARD_DESCRIPTION: "Reward Description",
  REDIRECTION_LINK: "Reward CTA Redirection Link",
  STREAK: "Streak",
  MILESTONE_LEVEL: "Milestone Level",
  REWARD_TYPE: "Reward Type"
};

const DROPDOWN_OPTIONS = {
  REWARD_TYPE: [
    { label: "Breathing Exercise", value: "BE" },
    { label: "LCP", value: "LCP" },
  ],
  CATEGORY: [
    { label: "In Service", value: "IN_SERVICE" },
    { label: "Out Service", value: "OUT_SERVICE" },
  ],
  OFFER_TYPE: [
    { label: "Discount", value: "DISCOUNT" },
    { label: "Free Access", value: "FREE_ACCESS" },
  ],
  STREAK: [
    { label: "Streak 1", value: "streak1" },
    { label: "Streak 2", value: "streak2" },
  ],
};

const RequiredLabel = ({ label }) => (
  <div className="label-wrapper">
    {label} <span className="text-red-500">*</span>
  </div>
);
const internalUrlOptions = DropdownOptions.InternalUrl;
const GamificationCreateCoins = () => {
  const params = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    reward_title: "",
    category_type: "",
    reward_type:"",
    offer_type: "",
    reward_offer: "",
    coin_value: "",
    reward_description: "",
    redirectionLink: "",
    reward_banner: "",
    reward_modal_banner: "",
    android_version: "",
    criteria: "",
    ios_version: "",
    ios_criteria: "",
    start_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    end_date: "",
    internal_for: "",
    internal_url: null,
    profile_data: true,
    custom_object: "",
    redirection_id: "",
    android_version: "",
    criteria: "",
    ios_version: "",
    ios_criteria: "",
    detail_cta_text: "",
    url_secondary: "",
    url_type_secondary: "",
    url_type:"INTERNAL",
    redirection_id_secondary: null,
    internal_for_secondary: "",
    internal_url_secondary: "",
    detail_cta_text_secondary: "",
    profile_data_secondary: false,
    custom_object_secondary: "",
    secondaryCTA: false,
  });
  const [versionLists, setVersionLists] = useState([]);
  const [iosversionLists, setIosVersionLists] = useState([]);
  const [errors, setErrors] = useState({});
  const [reward_banner, setreward_banner] = useState(null);
  const [reward_modal_banner, setreward_modal_banner] = useState(null);
  const [hideFields, setHideFields] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  // const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: "1",
    name: "English",
    short_name: "en",
    display_order: 1,
    status: "ACTIVE",
    label: "+ English",
    value: "English",
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    if (name === "category_type") {
      if (value === "OUT_SERVICE") {
        // Clear URL-related fields when category type is OUT_SERVICE
        setState({
          ...state,
          [name]: value,
          url_type: "",
          url: "",
          redirection_id: "",
          internal_for: "",
          internal_url: "",
          profile_data: true,
          custom_object: "",
          detail_cta_text: "",
          // Clear secondary CTA fields as well
          url_type_secondary: "",
          url_secondary: "",
          redirection_id_secondary: "",
          internal_for_secondary: "",
          internal_url_secondary: "",
          detail_cta_text_secondary: "",
          profile_data_secondary: false,
          custom_object_secondary: "",
          secondaryCTA: false
        });
      } else {
        // For IN_SERVICE, set default URL type to INTERNAL
        setState({
          ...state,
          [name]: value,
          url_type: "INTERNAL" // Set default URL type for IN_SERVICE
        });
      }
    } else {
      // Handle all other form field changes normally
      setState({ ...state, [name]: value });
    }
    
    // Clear any errors for the changed field
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
  
    // Required fields validation
    if (!state.reward_title?.trim()) {
      newErrors.reward_title = "Reward Title is required";
      isValid = false;
    }
  
    if (!state.category_type) {
      newErrors.category_type = "Reward Category is required";
      isValid = false;
    }
    if (!state.reward_type) {
      newErrors.reward_type = "Reward Type is required";
      isValid = false;
    }
    if (!state.offer_type) {
      newErrors.offer_type = "Reward Offer Type is required";
      isValid = false;
    }
  
    // Reward Offer validation based on offer type
    if (!state.reward_offer) {
      newErrors.reward_offer = "Reward Offer is required";
      isValid = false;
    } else if (state.offer_type === "DISCOUNT") {
      const numericValue = parseFloat(state.reward_offer);
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
        newErrors.reward_offer = "Please enter a valid percentage (0-100)";
        isValid = false;
      }
    } else if (state.offer_type === "FREE_ACCESS") { // Free Access
      const days = parseInt(state.reward_offer);
      if (isNaN(days) || days <= 0) {
        newErrors.reward_offer = "Please enter a valid number of days";
        isValid = false;
      }
    }
  
    // Coin Value validation
    if (!state.coin_value) {
      newErrors.coin_value = "Coin Value is required";
      isValid = false;
    } else {
      const coinValue = parseInt(state.coin_value);
      if (isNaN(coinValue) || coinValue <= 0) {
        newErrors.coin_value = "Please enter a valid coin value";
        isValid = false;
      }
    }
  
    // Reward Description validation
    if (!state.reward_description?.trim()) {
      newErrors.reward_description = "Reward Description is required";
      isValid = false;
    }
  
    // Image validations
    if (!state.reward_banner && !reward_banner) {
      newErrors.reward_banner = "Hub Banner Image is required";
      isValid = false;
    }
  
    if (!state.reward_modal_banner && !reward_modal_banner) {
      newErrors.reward_modal_banner = "Modal Banner Image is required";
      isValid = false;
    }
  
    // Conditional Redirection Link validation for IN_SERVICE category
    // if (state.category_type === "IN_SERVICE") {
    //   if (!state.redirectionLink?.trim()) {
    //     newErrors.redirectionLink = "Redirection Link is required for In Service category";
    //     isValid = false;
    //   } else if (!isValidUrl(state.redirectionLink)) {
    //     newErrors.redirectionLink = "Please enter a valid URL";
    //     isValid = false;
    //   }
    // }
    if (state.category_type === "IN_SERVICE") {
      // Validation for Internal URL type
      if (state.url_type === "INTERNAL") {
        if (!state.url?.trim()) {
          newErrors.url = "Internal URL is required for Internal category";
          isValid = false;
        }
      }
      
      // Validation for Internal PWA type
      if (state.url_type === "INTERNAL_PWA") {
        if (!state.internal_for?.trim()) {
          newErrors.internal_for = "Internal For is required for Internal PWA";
          isValid = false;
        }
    
        if (!state.internal_url?.trim()) {
          newErrors.internal_url = "Internal URL is required for Internal PWA";
          isValid = false;
        }
    
        if (!state.custom_object?.trim()) {
          newErrors.custom_object = "Custom Object is required for Internal PWA";
          isValid = false;
        }
    
        if (state.profile_data === undefined || state.profile_data === null) {
          newErrors.profile_data = "Profile Data is required for Internal PWA";
          isValid = false;
        }
      }
      
      // Validation for External and External PWA types
      if (state.url_type === "EXTERNAL" || state.url_type === "EXTERNAL_PWA") {
        if (!state.url?.trim()) {
          newErrors.url = "External URL is required";
          isValid = false;
        }
      }
    }

    // Version validations
    if (!state.android_version) {
      newErrors.android_version = "Android Version is required";
      isValid = false;
    }
  
    if (!state.ios_version) {
      newErrors.ios_version = "iOS Version is required";
      isValid = false;
    }
  
    // Date validations
    if (!state.start_date) {
      newErrors.start_date = "Start Date is required";
      isValid = false;
    }
  
    if (state.end_date && new Date(state.end_date) <= new Date(state.start_date)) {
      newErrors.end_date = "End date must be after start date";
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };
  
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  const VersionRange = [
    {
      value: "GREATER_THAN",
      label: "Greater Than",
    },
    {
      value: "LESS_THAN",
      label: "Lesser Than",
    },
    {
      value: "EQUALS",
      label: "Equals",
    },
  ];
  useEffect(() => {
    async function fetchVersionControl() {
      let response = await getVersionControl("Android");

      const data = response.data.available_versions;
      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setVersionLists(newState);
      let iosResponse = await getVersionControl("IOS");

      const iosdata = iosResponse.data.ios_available_versions;
      let newiosState = iosdata.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setIosVersionLists(newiosState);
    }
    fetchVersionControl();
  }, []);
  const cancelMedia = async (e, imageType) => {
    if (imageType === "hub") {
      setState({ ...state, reward_banner: "" });
      setreward_banner(null);
    } else {
      setState({ ...state, reward_modal_banner: "" });
      setreward_modal_banner(null);
    }
  };
  // useEffect(() => {
    
  //   if (state.url_type == 'INTERNAL') {
  //     setDisabled({ externalRedirect: true, internalApp: false, cta: false });
  //   } else if (state.url_type == 'EXTERNAL') {
  //     setDisabled({ cta: true, internalApp: true, externalRedirect: false });
  //   } else if (state.url_type == 'EXTERNAL_PWA') {
  //     setDisabled({ internalApp: true, externalRedirect: false, cta: false });
  //   }
  // }, [state.url_type]);
  const handleMediaUpload = async (e, imageType) => {
    if (!e) return;
    const file = e.target.files[0];

    if (
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png") &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    }

    try {
      const resp = await uplaodMedia("image", file);
      let reader = new FileReader();

      if (imageType === "hub") {
        setState({ ...state, reward_banner: resp?.filename });
        reader.onloadend = () => setreward_banner(reader.result);
      } else {
        setState({ ...state, reward_modal_banner: resp?.filename });
        reader.onloadend = () => setreward_modal_banner(reader.result);
      }

      reader.readAsDataURL(file);
      Toast.success(file?.name + " uploaded");
      setErrors({ ...errors, [`thumbnailImage${imageType}`]: "" });
    } catch (error) {
      Toast.error("Error uploading image");
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      // Get all error messages
      const errorMessages = Object.values(errors).filter((error) => error);

      // Show first error message in toast
      if (errorMessages.length > 0) {
        Toast.error(errorMessages[0]);
      } else {
        Toast.error("Please fill all required fields correctly");
      }
      return;
    }

    const payload = {
      reward_title: state.reward_title,
      category_type: state.category_type,
      reward_type: state.reward_type,
      offer_type: state.offer_type,
      reward_offer: state.reward_offer,
      streak: state.streak,
      milestone: state.milestone,
      coin_value: state.coin_value,
      redirection_link: state.redirectionLink,
      reward_banner: state.reward_banner,
      reward_modal_banner: state.reward_modal_banner,
      android_version: state.android_version,
      criteria: state.criteria,
      reward_description: state.reward_description,
      ios_version: state.ios_version,
      ios_criteria: state.ios_criteria,
      redemption_type: "COIN",
      start_date: state.start_date,
      end_date: state.end_date,
      url_type: state.category_type === "OUT_SERVICE" ? "" : state.url_type,
      url: state.category_type === "OUT_SERVICE" ? "" : state.url,
      redirection_id: state.redirection_id,
      internal_for: state.internal_for,
      internal_url: state.internal_url ? state.internal_url : null,
      profile_data: state.profile_data,
      custom_object: state.custom_object,
      url_secondary: state?.url_secondary,
      url_type_secondary: state?.url_type_secondary,
      redirection_id_secondary: state?.redirection_id_secondary,
      internal_for_secondary: state?.internal_for_secondary,
      internal_url_secondary: state?.internal_url_secondary,
      detail_cta_text_secondary: state?.detail_cta_text_secondary,
      profile_data_secondary: state?.profile_data_secondary,
      custom_object_secondary: state?.custom_object_secondary,
      video_url: state.video_url ? state.video_url : "",
      cta_image: state.cta_image,
      mrp: state.mrp,
      discounted_price: state.discounted_price,
      discount_percentage: state.discount_percentage,
      redirect_api_url: state.redirect_api_url ? state.redirect_api_url : null,
    };
    try {
      let responseObj;
      if (params.id) {
        payload.reward_id = params.id;
        if (activeTab?.name === "English") {
          responseObj = await updateGamificationRewards(payload);
          if(responseObj.response.code === 200){
            Toast.success("Reward updated successfully");
            history.goBack();
          }else{
            Toast.error("Failed to update Reward")
          };
          
          
        } else {
          let newPayload = {
            reward_id: params.id,
            reward_title: payload?.reward_title,
            reward_banner: payload?.reward_banner,
            reward_modal_banner: payload?.reward_modal_banner,
            reward_description: payload?.reward_description,
          };
          responseObj = await updateLocalisationGamificationRewards(
            newPayload,
            activeTab?.short_name
          );
          if(responseObj.response.code === 200){
            Toast.success(responseObj?.data?.data);
            history.goBack();
          }else{
            Toast.error(responseObj?.data?.data);
          }
          
          
        }
      } else {
        responseObj = await createGamificationReward(payload);
        if(responseObj.response.code === 200){
          Toast.success("Reward created successfully");
          history.goBack();
        }else{
          Toast.error("Failed to update Reward")
        };
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.error(
        params?.id ? "Error updating reward" : "Error creating reward"
      );
    }
  };
  useEffect(() => {
    if (params?.id) {
      if (activeTab.short_name === "en") {
        getGamificationRewardDetailsByID(params?.id);
      } else {
        getLocalizationRewardById(params.id, activeTab.short_name);
      }
    }
  }, [activeTab.short_name, params?.id]);
  const getGamificationRewardDetailsByID = async (id) => {
    setPageLoading(true);
    const response = await getGamificatinRewardsById(id);
    if (response) {
      if (activeTab?.name === "English") {
        setState({
          ...state,
          reward_title: response?.reward_title,
          category_type: response?.category_type,
          reward_type: response?.reward_type,
          offer_type: response?.offer_type,
          milestone: response?.milestone,
          reward_offer: response?.reward_offer,
          reward_description: response?.reward_description,
          daily_capping: response.activity?.daily_capping,
          reward_banner: response?.reward_banner || "",
          reward_modal_banner: response?.reward_modal_banner || "",
          android_version: response?.android_version.toString(),
          criteria: response?.criteria,
          ios_criteria: response?.ios_criteria,
          ios_version: response?.ios_version.toString(),
          coin_value: response?.coin_value,
          start_date: response.start_date
            ? moment(response.start_date).format("YYYY-MM-DD HH:mm:ss")
            : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          end_date: response.end_date
            ? moment(response.end_date).format("YYYY-MM-DD HH:mm:ss")
            : "",
            url_type: response?.url_type,
          url: response?.url,
          redirection_id: response?.redirection_id,
          internal_url: response?.internal_url,
          internal_for: response?.internal_for,
        });
      } else {
        setState((prev) => ({
          ...prev,
          reward_title: response?.reward_title ? response?.reward_title : "",
        }));
      }
      let icon_banner = response.reward_banner
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${response.reward_banner}`
        : null;
      let icon_banner_modal = response.reward_modal_banner
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${response.reward_modal_banner}`
        : null;
      setreward_banner(icon_banner);
      setreward_modal_banner(icon_banner_modal);
    }
    setPageLoading(false);
  };

  const getLocalizationRewardById = async (id, language) => {
    try {
      setPageLoading(true);
      const response = await getLocalasationRewardByid(id, language);

      if (response) {
        // Update only localized fields while preserving other data
        setState((prev) => ({
          ...prev,
          reward_title: response.reward_title ? response.reward_title : "",
        }));
        let icon_banner = response.reward_banner
          ? `${process.env.REACT_APP_S3_BUCKET_URL}/${response.reward_banner}`
          : null;
        let icon_banner_modal = response.reward_modal_banner
          ? `${process.env.REACT_APP_S3_BUCKET_URL}/${response.reward_modal_banner}`
          : null;
        setreward_banner(icon_banner);
        setreward_modal_banner(icon_banner_modal);
      }
    } catch (error) {
      console.error(`Error fetching ${language} localization:`, error);
    } finally {
      setPageLoading(false);
    }
  };
  const renderImageUpload = (type, image, setImage) => (
    <div className="col-sm-6">
      <div className="mt-4">
        {image && (
          <p className="cancel" onClick={(e) => cancelMedia(e, type)}>
            Cancel Image
          </p>
        )}
      </div>
      <div className="upload-buttons">
        <input
          type="file"
          id={`thumbnailFile${type}`}
          style={{ display: "none" }}
          name={`thumbnail_image_${type}`}
          onChange={(e) => handleMediaUpload(e, type)}
        />
        <div className="upload-label">
          <RequiredLabel
            label={
              type === "hub"
                ? FORM_LABELS.REWARD_BANNER_HUB
                : FORM_LABELS.REWARD_BANNER_MODAL
            }
          />
          <Button
            onClick={() => {
              const image = document.getElementById(`thumbnailFile${type}`);
              image.click();
            }}
            isDisabled={image}
            type="button"
            name={image ? "Uploaded" : "Upload Image"}
          />
        </div>
        {errors[`thumbnailImage${type}`] && (
          <div className="text-red-500 text-sm mt-1">
            {errors[`thumbnailImage${type}`]}
          </div>
        )}
        {image && (
          <div className="attachment_img_wrapper">
            <img src={image} alt="sample" className="feed__attachment" />
            <div className="attachment_img_wrapper_close">
              <img
                src={CloseBlackIcon}
                alt="Remove"
                onClick={(e) => cancelMedia(e, type)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val.name === "English") {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
    // Perform any other actions on tab change
  };
  const { LanguageOptions } = useTabBarOptions();
  return (
    <BasePage>
      <h4>{params.id ? "Update" : "Add"} Reward - Coin</h4>
      <div className="row">
        {params.id && (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={"service-tab-style"}
          />
        )}

        <div className="col-sm-6">
          <NewInput
            label={<RequiredLabel label={FORM_LABELS.REWARD_TITLE} />}
            name="reward_title"
            value={state.reward_title}
            onChange={handleTextChange}
            error={errors.reward_title}
          />
        </div>
        <div className="col-sm-3">
          <Dropdown
            label={<RequiredLabel label={FORM_LABELS.CATEGORY} />}
            placeholder="Select Category"
            name="category_type"
            value={state.category_type}
            options={DROPDOWN_OPTIONS.CATEGORY}
            handleChange={handleTextChange}
            error={errors.category_type}
            isDisabled={disableFields}
          />
        </div>
        <div className="col-sm-3">
          <Dropdown
            label={<RequiredLabel label={FORM_LABELS.REWARD_TYPE} />}
            placeholder="Select Reward Type"
            name="reward_type"
            value={state.reward_type}
            options={DROPDOWN_OPTIONS.REWARD_TYPE}
            handleChange={handleTextChange}
            error={errors.reward_type}
            isDisabled={disableFields}
          />
        </div>
        <div className="col-sm-6">
          <Dropdown
            label={<RequiredLabel label={FORM_LABELS.OFFER_TYPE} />}
            placeholder="Select Offer Type"
            name="offer_type"
            value={state.offer_type}
            options={DROPDOWN_OPTIONS.OFFER_TYPE}
            handleChange={handleTextChange}
            error={errors.offer_type}
            isDisabled={disableFields}
          />
        </div>
        <div className="col-sm-3">
          <NumberInput
            label={<RequiredLabel label={FORM_LABELS.OFFER} />}
            name="reward_offer"
            value={state.reward_offer}
            onChange={handleTextChange}
            placeholder={
              state.offer_type === "DISCOUNT"
                ? "Enter percentage"
                : "Enter days"
            }
            error={errors.reward_offer}
            isDisabled={disableFields}
          />
        </div>
        <div className="col-sm-3">
          <NumberInput
            label={<RequiredLabel label={FORM_LABELS.COIN_VALUE} />}
            name="coin_value"
            value={state.coin_value}
            onChange={handleTextChange}
            error={errors.coin_value}
            isDisabled={disableFields}
          />
        </div>
        <div className="row">
          {renderImageUpload("hub", reward_banner, setreward_banner)}
          {renderImageUpload(
            "modal",
            reward_modal_banner,
            setreward_modal_banner
          )}
        </div>
        <div className="col-sm-12 mb-4">
          <NewInput
            label={<RequiredLabel label={FORM_LABELS.REWARD_DESCRIPTION} />}
            name="reward_description"
            value={state.reward_description}
            onChange={handleTextChange}
            error={errors.reward_description}
            isDisabled={disableFields}
          />
        </div>
        <div className="col-sm-12">
          {/* <NewInput
            label={
              state.category_type === "IN_SERVICE" ? (
                <RequiredLabel label={FORM_LABELS.REDIRECTION_LINK} />
              ) : (
                FORM_LABELS.REDIRECTION_LINK
              )
            }
            name="redirectionLink"
            value={state.redirectionLink}
            onChange={handleTextChange}
            error={errors.redirectionLink}
          /> */}
        {state.category_type === "IN_SERVICE" &&
        <CTAType
            cta_type={state.url_type}
            handleChange={(key, data) => {
              console.log(key, data);
              if (key === "cta_type") {
                if (!data) {
                  // When no data (null), set to 'INTERNAL' instead of leaving it blank
                  setState({
                    ...state,
                    url_type: "INTERNAL",
                    url: "",
                    detail_cta_text: "",
                  });
                } else {
                  setState({ 
                    ...state, 
                    url_type: data,
                    // Clear internal-related fields when changing URL type
                    url: "",
                    internal_for: "",
                    internal_url: "",
                    profile_data: true,
                    custom_object: "",
                    redirection_id: "",
                    detail_cta_text: "",
                  });
                }
              } else {
                if (
                  key === "url" &&
                  (data !== "zoom_live_session" ||
                    data !== "infinite_scroll" ||
                    data !== "group_of_collection")
                ) {
                  setState({ ...state, [key]: data, redirection_id: "" });
                } else if (
                  key === "url_secondary" &&
                  (data !== "zoom_live_session" ||
                    data !== "infinite_scroll" ||
                    data !== "group_of_collection")
                ) {
                  setState({
                    ...state,
                    [key]: data,
                    redirection_id_secondary: "",
                  });
                } else {
                  setState({ ...state, [key]: data });
                }
              }
            }}
            internalUrl={internalUrlOptions}
            url={state.url}
            handleTextChange={handleTextChange}
            internal_for={state.internal_for}
            internal_url={state.internal_url}
            profile_data={state.profile_data}
            custom_object={state.custom_object}
            redirection_id={state.redirection_id}
            isDisabled={disableFields}
            secondaryCTA={state.secondaryCTA}
            cta_type_secondary={state.url_type_secondary}
            url_secondary={state.url_secondary}
            internal_for_secondary={state.internal_for_secondary}
            internal_url_secondary={state.internal_url_secondary}
            profile_data_secondary={state.profile_data_secondary}
            custom_object_secondary={state.custom_object_secondary}
            redirection_id_secondary={state.redirection_id_secondary}
            ctaText={true}
            detail_cta_text={state.detail_cta_text}
            detail_cta_text_secondary={state.detail_cta_text_secondary}
            // selectedItem={selectedItem}
            redirect_api_url={state.redirect_api_url}
            hideNoCTA={true}
          />}
        </div>
        <div className="mb-4">
        <VersionDropDown
          android_version={state.android_version}
          criteria={state.criteria}
          versionLists={versionLists}
          iosLists={iosversionLists}
          VersionRange={VersionRange}
          handleTextChange={handleTextChange}
          ios_version={state.ios_version}
          ios_criteria={state.ios_criteria}
        />
        </div>
        <Calender
          onStartDateSelected={(e) => {
            setState({ ...state, start_date: e });
          }}
          onEndDateSelected={(e) => {
            setState({ ...state, end_date: e });
          }}
          startDate={state.start_date}
          endDate={state.end_date}
          validated={true}
          activate={true}
          key={state.end_date}
        />
        <div className="row mt-4">
          <div className="userFormArea">
            <Button
              name={params.id ? "Update" : "Save"}
              onClick={handleSubmit}
            />
            <label
              style={{ paddingLeft: "10px", cursor: "pointer" }}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </label>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default GamificationCreateCoins;
