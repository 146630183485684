import { useState } from 'react';
import { dataFormats } from 'app/utils/dateChecker';

const CustomTable = ({ data, tableHeader, onSort }) => {
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'asc'
  });

  const sortableColumns = ['refilled_on', 'created_on'];

  const handleSort = (field) => {
    if (!sortableColumns.includes(field)) return;

    let direction = 'asc';
    if (sortConfig.key === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: field, direction });
    onSort(field, direction);
  };

  const getSortIcon = (field) => {
    if (!sortableColumns.includes(field)) return null;

    if (sortConfig.key !== field) {
      return <span className="text-gray-400" style={{marginLeft:'10px'}}>↕</span>;
    }
    return sortConfig.direction === 'asc' 
      ? <span className="text-gray-700" style={{marginLeft:'10px'}}>↑</span>
      : <span className="text-gray-700" style={{marginLeft:'10px'}}>↓</span>;
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {tableHeader.map((item, i) => (
              <th
                key={i}
                className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                  sortableColumns.includes(item.accessor) ? 'cursor-pointer hover:bg-gray-100' : ''
                }`}
                onClick={() => handleSort(item.accessor)}
              >
                <div className="flex items-center">
                  {item.Header}
                  {getSortIcon(item.accessor)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data && data.length > 0 ? (
            data.map((items, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 whitespace-nowrap">
                  {items.phone_number || 'NA'}
                </td>
                <td className="px-6 whitespace-nowrap">
                  {items.refilled_on
                    ? dataFormats(items.refilled_on, 'DD MMM YYYY HH:mm:ss')
                    : '-'}
                </td>
                <td className="px-6 whitespace-nowrap">
                  {items.created_on
                    ? dataFormats(items.created_on, 'DD MMM YYYY HH:mm:ss')
                    : '-'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td 
                colSpan={tableHeader.length} 
                className="px-6 py-8 whitespace-nowrap text-center text-gray-500"
              >
                <div className="flex flex-col items-center justify-center space-y-2">
                  <svg 
                    className="w-12 h-12 text-gray-400" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M12 14h.01M12 16h.01M12 18h.01M12 20h.01M12 22h.01" 
                    />
                  </svg>
                  <p className="text-base">No data found</p>
                  <p className="text-sm text-gray-400">
                    Try adjusting your search or filter criteria
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;