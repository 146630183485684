import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import TabBar from 'app/components/NewDesign/TabBar';
// Components
// import BannerCard from 'components/BannerCard';
import ValidationMessage from 'app/utils/ValidationMessage';
import Calender from 'components/Calender';
import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import useWebinarForm from 'components/useWebinarForm';
// import RichTextEditor from 'components/RichTextEditor';
import Attachment from 'app/components/Attachment';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import HtmlEditor from 'components/HtmlEditor';
import NewInput from 'components/NewInput';
import TextArea from 'components/Textarea';
// import NumberInput from 'components/UI/NumberInput';
import CTAWebinarComponent from 'components/CTAWebinarComponent';
// API
import {
  addOrUpdateRoles,
  addOrUpdateWebinar,
  deleteWebinarHost,
  deleteWebinarSpeaker,
  getWebinarById,
  updateWebinar,
} from 'app/api/webinar';
import moment from 'moment';
// Constants
import { APP_CONSTANTS, URLType } from 'constants/app-constants';

// Utils
import WebinarCard from 'app/components/WebinarCard';
import useContentSpeekerForm from 'app/components/useContentSpeekerForm';
import useContentWebinarForm from 'app/components/useContentWebinarForm';
import RadioButton from 'app/shared/components/RadioButton';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import './index.scss';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import QuillTextEditor from 'app/shared/components/QuillTextEditor';

const CreateWebinar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [videoThumbnail, setVideoThumbnail] = useState({});

  const [showError, setShowError] = React.useState(false);
  const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });

  const activate = true;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [passStatus, setpassStatus] = useState();
  // const [webinarError, set] = useState(null);
  const validated = true;
  const [speakerdeletedId, setSpeakerdeletedId] = useState([]);
  const [hostdeletedId, setHostdeletedId] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [htmlContent, setHTMLContent] = useState('');
  const [selectedItem, setSelectedItem] = useState('webinar');
  const [webinarId, setWebinarId] = useState(location?.state?.webinar_id);

  const { values, error, updateValue, handleChange, count } =
    useWebinarForm(validate);

  const { hostValues, updatehostValue, addWebinarSection, removeHostSection } =
    useContentWebinarForm(validate);

  console.log('values', values);

  const {
    Speekervalues,
    updateSpeekerValue,
    addSpeekerSection,
    removespeakerSection,
  } = useContentSpeekerForm(validate);

  const radioMenu = [
    {
      label: 'Webinar',
      id: '1',
      value: 'webinar',
    },
    {
      label: 'Zoom Meeting',
      id: '2',
      value: 'zoomMeeting',
    },
  ];

  console.log('videoThumbnail', videoThumbnail);
  useEffect(() => {
    if (location.state && location.state.webinar_id) {
      // setWebinarId(data.webinar_id)
      getDataById(location.state?.webinar_id);
    }
  }, [activeTab?.short_name]);

  const getDataById = async (id) => {
    if (!id) {
      return;
    }
    let lang = activeTab?.short_name ? activeTab?.short_name : '';
    if (!lang) return;
    setPageLoading(true);
    let response = await getWebinarById(id, lang);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      const data = response.data;
      setHTMLContent(data.description);
      let url = '';
      let externalUrl = '';

      if (data.webinar_record_link) {
        externalUrl = data.webinar_record_link ? data.webinar_record_link : '';
      }
      if (data.webinar_recording) {
        url = data.webinar_recording ? data.webinar_recording : '';
      }
      const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
      let newData = {
        title: data.title ? data.title : '',
        StartDate: moment(data.happening_at).local().format(dateTimeFormat),
        EndDate: moment(data.ending_at).local().format(dateTimeFormat),
        description: data.description,
        attachmentFile: data?.thumbnail_url ? data.thumbnail_url : null,
        urlType: data.webinar_record_link
          ? 'EXTERNAL'
          : data.webinar_recording
          ? 'INTERNAL'
          : '',
        url: url,
        externalUrl: externalUrl,
        videoThumbnail: data?.past_video_thumbnail
          ? data?.past_video_thumbnail
          : null,
        webinarLink: data?.live_stream_url ? data.live_stream_url : '',
        webinarId: data.webinar_id,
        speakers: [],
        // removespeakerSection: [],
        hosts: [],
        webinars: {},
        meeting_id: data.meeting_id ? data.meeting_id : '',
        passcode: data.pass_code ? data.pass_code : '',
      };
      if (data.meeting_id) {
        setSelectedItem('zoomMeeting');
      }

      setStartDate(newData.StartDate);
      setEndDate(newData.EndDate);

      if (data.organizers && data.organizers.length > 0) {
        const data1 = [];
        let { removedBanners } = hostValues;
        data.organizers.forEach((webinar, index) => {
          if (webinar && webinar.designation === 'host') {
            data1.push({
              id: `webinar_${index}`,
              hostId: data1.length + 1,
              name: webinar.name,
              designation: webinar.specialist,
              attachmentFile: webinar?.thumbnail_url
                ? webinar?.thumbnail_url
                : null,
              displayOrder: webinar.experience,
              speakerId: webinar.id,
              removedBanners,
            });
          }
        });
        newData.hosts = data1;
      }

      if (data.organizers && data.organizers.length > 0) {
        const data2 = [];
        let { removedBanners } = Speekervalues;
        data.organizers.forEach((webinar, index) => {
          if (webinar && webinar.designation === 'speaker') {
            data2.push({
              id: `webinar_${index}`,
              // webinarId: webinar.webinar_id,
              speekerId: data2.length + 1,
              name: webinar.name,
              description: webinar.specialist,
              attachmentFile: webinar.thumbnail_url
                ? webinar.thumbnail_url
                : null,
              displayOrder: webinar.experience,
              speakerId: webinar.id,
              removedBanners,
            });
          }
        });
        newData.speakers = data2;
      }
      newData.webinars = newData;

      updateValue(newData);

      updateSpeekerValue({
        speekers: newData.speakers,
      });
      updatehostValue({
        hosts: newData.hosts,
      });
    }
  };

  const OnhandleWebinar = (e, index) => {
    let data = { ...values };

    data[e.target.id] = e.target.value;

    updateValue({
      ...data,
      webinars: data,
    });
  };

  const radioButtonSelected = (item) => {
    if (activeTab?.name === 'English') {
      setSelectedItem(item.value);
    }
  };

  const handleURLChange = (e) => {
    let data = { ...values };
    data[e.target.id] = e.target.value;
    updateValue({
      ...data,
      webinars: data,
    });
  };

  const OnhandleHost = (e, index) => {
    const data = hostValues.hosts.map((item) => {
      if (item.hostId === index + 1) {
        item[e.target.id] = e.target.value;
      }
      return item;
    });

    updatehostValue({
      ...hostValues,
      hosts: data,
    });
  };

  const OnhandleSpeeker = (e, index) => {
    const data = Speekervalues.speekers.map((item) => {
      if (item.speekerId === index + 1) {
        item[e.target.id] = e.target.value;
      }
      return item;
    });
    updateSpeekerValue({
      ...Speekervalues,
      speekers: data,
    });
  };

  const onFileSelected = (file, id) => {
    let data = { ...values };
    data['attachmentFile'] = file;

    // if (file === null || file === undefined) {
    //   setVideoThumbnail(null);
    // }

    updateValue({
      ...data,
      webinars: data,
    });
  };

  const onWebinarRecording = (file, id) => {
    let data = { ...values };
    data['webinarRecording'] = file;
    updateValue({
      ...data,
      webinars: data,
    });
  };

  const onVideoThumbnail = (thumbnail) => {
    setVideoThumbnail(thumbnail);
  };

  const onFileHostSelected = (file, id) => {
    // debugger;
    let newHostData = [...hostValues.hosts];
    if (newHostData && newHostData.length > 0 && file) {
      newHostData = newHostData.map((item) => {
        // if (item.hostId === id && id) {
        item.attachmentFile = file;
        // }
        return item;
      });
      updatehostValue({
        ...hostValues,
        hosts: newHostData,
      });
    }
  };

  const onFileSpeekerSelected = (file, id) => {
    let newHostData = [...Speekervalues.speekers];
    if (newHostData && newHostData.length > 0 && file) {
      newHostData = newHostData.map((item) => {
        // if (item.hostId === id && id) {
        item.attachmentFile = file;
        // }
        return item;
      });
      updateSpeekerValue({
        ...Speekervalues,
        speekers: newHostData,
      });
    }
  };

  const handleBlur = (e) => {
    if (e.target.value.length > 60) {
      // Send an API request to check if email is already taken.
      setShowError(true);
      // alert('Please enter only 60 Characters');
    } else {
      setShowError(false);
    }
  };

  // const handleHTMLBlur = (e) => {
  //   if (e.target.value.length > 500) {
  //     // Send an API request to check if email is already taken.
  //     setShowHTMLError(true);
  //     // alert('Please enter only 60 Characters');
  //   } else {
  //     setShowHTMLError(false);
  //   }
  // };

  const handleAddSpeekerSection = () => {
    addSpeekerSection();
  };

  const handleRemoveSpeekerSection = (index, id) => {
    removespeakerSection(index);
    setSpeakerdeletedId((prevState) => [...prevState, id]);
  };

  const handleRemoveHostSection = (index, id) => {
    removeHostSection(index);
    setHostdeletedId((prevState) => [...prevState, id]);
  };

  const callAddOrUpdateAPI = async () => {
    let fileNames = values;
    console.log('fileNames', fileNames);
    let { title, webinarLink, StartDate, EndDate, meeting_id, passcode } =
      values;

    // Validations Start

    let defaultVal = '';
    let consData = {
      title: fileNames.title,
      webinar_link: fileNames.webinarLink,
      webinar_image: fileNames?.attachmentFile
        ? fileNames?.attachmentFile
        : null,
      past_video_thumbnail: videoThumbnail ? videoThumbnail : null,
      start_date: defaultVal,
      end_date: defaultVal,
      meeting_id,
      passcode,
    };

    if (
      (fileNames.webinarRecording || fileNames.externalUrl) &&
      fileNames.urlType
    ) {
      consData.url_type = fileNames.urlType;
      if (fileNames.urlType === URLType.EXTERNAL) {
        consData.webinar_record_link = fileNames.externalUrl;
      }
      if (fileNames.urlType === URLType.INTERNAL) {
        consData.webinar_recording = fileNames.webinarRecording;
      }
    }

    if (StartDate) {
      consData.start_date = StartDate;
    }

    if (EndDate) {
      if (moment(EndDate)._d.getDate() <= moment(StartDate)._d.getDate() + 1) {
        consData.end_date = EndDate;
      }
    }
    // let StartDateVal = moment(StartDate)._d.getDate() + 1;
    // let EndDateVal = moment(EndDate)._d.getDate();

    let content;
    if (editorRef.current) {
      content = editorRef.current.value;
      consData.description = content;
    }

    if (!title || title.length > 60) {
      Toast.error(ValidationMessage.TEXT_TITLE);
      return;
    }

    if (
      !editorRef.current.value ||
      editorRef.current.value.length > 500
    ) {
      Toast.error(ValidationMessage.TEXT_DESCRIPTION_WEBINAR);
      return;
    }

    if (!StartDate) {
      Toast.error(ValidationMessage.START_DATE);
      return;
    }

    if (!EndDate) {
      Toast.error(ValidationMessage.END_DATE);
      return;
    }

    if (StartDate > EndDate) {
      Toast.error(ValidationMessage.END_DATE_GREATER);
      return;
    }
    if (!(moment(EndDate)._d.getDate() <= moment(StartDate)._d.getDate() + 1)) {
      Toast.error(ValidationMessage.END_DATE_VALIDATION);
      return;
    }
    console.log('selectedItem', selectedItem);
    console.log('fileNames', fileNames);

    if (
      activeTab.short_name === 'en' &&
      selectedItem === 'webinar' &&
      !fileNames?.urlType
    ) {
      Toast.error('Please select External URL or Add an webinar attachment');
      return;
    }

    if (
      activeTab.short_name === 'en' &&
      selectedItem === 'webinar' &&
      fileNames.urlType &&
      !fileNames.webinarRecording &&
      !fileNames.externalUrl &&
      !fileNames.webinarLink
    ) {
      Toast.error('Please enter webinar link or webinar attachment');
      return;
    }

    if (!webinarLink) {
      delete consData.webinar_link;
    }

    setPageLoading(true);
    let responseObj = {};

    if (activeTab?.short_name === 'en') {
      if (selectedItem === 'webinar') {
        delete consData.meeting_id;
        delete consData.passcode;
      } else if (selectedItem === 'zoomMeeting') {
        delete consData.webinar_link;
      }
      responseObj = await addOrUpdateWebinar(consData, webinarId);
    } else {
      let updateData = {
        title: fileNames.title,
        webinar_link: fileNames.webinarLink,
        webinar_image: fileNames?.attachmentFile
          ? fileNames?.attachmentFile
          : null,
        description: consData.description,
        meeting_id: fileNames.meeting_id,
        passcode: fileNames.passcode,
        lang: activeTab?.short_name,
      };
      if (selectedItem === 'webinar') {
        delete updateData.meeting_id;
        delete updateData.passcode;
      } else if (selectedItem === 'zoomMeeting') {
        delete updateData.webinar_link;
      }
      responseObj = await updateWebinar(updateData, webinarId);
    }
    // const length =  Speekervalues.speekers.length > hostValues.hosts.length
    //     ? Speekervalues.speekers.length
    //     : hostValues.hosts.length;
    if (activeTab?.short_name === 'en') {
      for (let index = 0; index < Speekervalues.speekers.length; index++) {
        // let hostValue = hostValues.hosts[index];
        let speekerValue = Speekervalues.speekers[index];

        let speekersData = {
          name: speekerValue.name,
          speaker_image: speekerValue?.attachmentFile
            ? speekerValue?.attachmentFile
            : null,
          specialisation: speekerValue.description,
          experience: speekerValue.displayOrder,
          role: 'speaker',
          speaker_update: speekerValue?.speakerId ? 1 : 0,
          removedBanners: [],
        };
        if (speekerValue?.speakerId) {
          speekersData.speaker_id = speekerValue?.speakerId;
        }
        if (!speekerValue.attachmentFile) {
          Toast.error(ValidationMessage.SPEAKER_IMAGE);
          return;
        }

        if (!speekerValue.name) {
          Toast.error(ValidationMessage.SPEAKER_NAME);
          return;
        }

        if (!speekerValue.displayOrder) {
          Toast.error(ValidationMessage.SPEAKER_PROFESSION);
          return;
        }
        if (!speekerValue.description) {
          Toast.error(ValidationMessage.SPEAKER_EXPERIANCE);
          return;
        }
        speekersData.webinar_id = webinarId
          ? webinarId
          : responseObj[0].data.webinar_id;

        await addOrUpdateRoles(speekersData);
      }

      // const removespeakerSection = async (data) => {
      //   setPageLoading(true);

      if (speakerdeletedId.length > 0) {
        let responseObj = await deleteWebinarSpeaker(
          webinarId,
          speakerdeletedId
        );
        // setPageLoading(false);
        if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
        ) {
          // loadNames();
          Toast.success(Message.Webinar.SPEAKER_DELETE_SUCCESS);
        }
      } else {
        // Toast.error(Message.Webinar.SPEAKER_DELETE_FAILURE);
      }

      if (hostdeletedId.length > 0) {
        let responseObj = await deleteWebinarHost(webinarId, hostdeletedId);
        // setPageLoading(false);
        if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
        ) {
          // loadNames();
          Toast.success(Message.Webinar.SPEAKER_DELETE_SUCCESS);
        }
      } else {
        // Toast.error(Message.Webinar.SPEAKER_DELETE_FAILURE);
      }
      // };

      for (let index = 0; index < hostValues.hosts.length; index++) {
        let hostValue = hostValues.hosts[index];
        // let speekerValue = Speekervalues.speekers[index];

        let hostsData = {
          name: hostValue.name,
          speaker_image: hostValue.attachmentFile,
          specialisation: hostValue.designation,
          experience: hostValue.displayOrder,
          speaker_update: hostValue?.speakerId ? 1 : 0,
          role: 'host',
        };
        if (hostValue?.speakerId) {
          hostsData.speaker_id = hostValue?.speakerId;
        }

        if (!hostValue.attachmentFile) {
          Toast.error(ValidationMessage.HOST_IMAGE);
          return;
        }

        if (!hostValue.name) {
          Toast.error(ValidationMessage.HOST_NAME);
          return;
        }

        if (!hostValue.designation) {
          Toast.error(ValidationMessage.HOST_PROFESSION);
          return;
        }
        if (!hostValue.displayOrder) {
          Toast.error(ValidationMessage.HOST_EXPERIANCE);
          return;
        }
        setPageLoading(true);

        hostsData.webinar_id = webinarId;
        hostsData.webinar_id = webinarId
          ? webinarId
          : responseObj[0].data.webinar_id;

        await addOrUpdateRoles(hostsData);
      }
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.length > 0 &&
        responseObj[0] &&
        responseObj[0].response &&
        responseObj[0].response.code === 200
      ) {
        Toast.success(Message.Webinar.CREATED_SUCCESS);
        history.goBack();
      } else {
        Toast.error(
          responseObj[0].response.alert[0] &&
            responseObj[0].response.alert[0].message
        );
        // Toast.error(Message.Webinar.CREATE_FAILURE);
      }
    } else {
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(Message.Webinar.CREATED_SUCCESS);
        history.goBack();
      } else {
        Toast.error(
          responseObj[0].response.alert[0] &&
            responseObj[0].response.alert[0].message
        );
        // Toast.error(Message.Webinar.CREATE_FAILURE);
      }
    }
    // setButton(true);
  };

  const handleCreateWebinar = (e) => {
    callAddOrUpdateAPI();
  };

  const onStartDateSelected = (e, date) => {
    let data = { ...values };
    data['StartDate'] = e;
    updateValue({
      ...data,
      webinars: data,
    });
  };

  const onEndDateSelected = (e, date) => {
    let data = { ...values };
    data['EndDate'] = e;
    updateValue({
      ...data,
      webinars: data,
    });
  };

  const handleAddWebinarSection = () => {
    addWebinarSection();
  };

  let isAddButtonDisabled = false;
  if (count >= 5) {
    isAddButtonDisabled = true;
  }

  const [hideFields, setHideFields] = useState(false);
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit a Webinar'
          goTo={APP_CONSTANTS.WEBINAR}
        />
        {webinarId ? (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <div className='content-selector'>
          <h2>Select Type</h2>
          {radioMenu.map((item, key) => (
            <RadioButton
              id={item.id}
              label={item.label}
              isChecked={selectedItem === item.value ? true : false}
              value={item.value}
              onSelect={() => {
                radioButtonSelected(item);
              }}
              name={item.label}
            />
          ))}
        </div>
        <NewInput
          label='Title'
          handleBlur={handleBlur}
          id='title'
          type='text'
          placeholder='Ex: Enter a Title'
          name='title'
          value={values.title}
          onChange={(e) => OnhandleWebinar(e)}
          errorText={passStatus}
        />
        {showError && (
          <p className='webinar-vali'>Please enter only 60 characters</p>
        )}
        {!hideFields && (
          <Calender
            onStartDateSelected={(e) => onStartDateSelected(e)}
            onEndDateSelected={(e) => onEndDateSelected(e)}
            startDate={startDate}
            endDate={endDate}
            validated={validated}
            activate={activate}
          />
        )}
        {selectedItem === 'webinar' && (
          <NewInput
            label='Webinar Link'
            id='webinarLink'
            name='webinarLink'
            value={values.webinarLink}
            placeholder='Ex: https://www.cipla.com'
            className='form-block'
            type='url'
            onChange={(e) => OnhandleWebinar(e)}
          />
        )}
        {selectedItem === 'zoomMeeting' && (
          <>
            {!hideFields ? (
              <>
                <NewInput
                  label='Meeting ID'
                  id='meeting_id'
                  name='meeting_id'
                  value={values.meeting_id}
                  placeholder=''
                  className='form-block'
                  autocomplete='off'
                  type='text'
                  onChange={(e) => OnhandleWebinar(e)}
                />
                <NewInput
                  label='Password'
                  id='passcode'
                  autocomplete='off'
                  name='passcode'
                  value={values.passcode}
                  placeholder=''
                  className='form-block'
                  type='text'
                  onChange={(e) => OnhandleWebinar(e)}
                />
              </>
            ) : null}
          </>
        )}
        Shashank 1
        <Attachment
          value={values?.attachmentFile ? values?.attachmentFile : null}
          // id={data.id}
          onFileSelected={onFileSelected}
          fileType='image'
          className='webinar-img'
          // label={`Add Banner ${index + 1}`}
        />
        {selectedItem === 'webinar' && (
          <CTAWebinarComponent
            attachmentFile={
              values?.webinarRecording ? values.webinarRecording : null
            }
            onVideoThumbnail={onVideoThumbnail ? onVideoThumbnail : null}
            videoThumbnail={
              values?.videoThumbnail ? values?.videoThumbnail : null
            }
            urlType={values.urlType}
            value={values.url}
            externalUrl={values.externalUrl ? values.externalUrl : null}
            onChange={handleChange}
            handleURLChange={(e) => handleURLChange(e)}
            onFileSelected={onFileSelected ? onFileSelected : null}
            onWebinarRecording={onWebinarRecording ? onWebinarRecording : null}
            errorText={error.url ? error.url : null}
            // webinar_record_link={values.webinar_record_link}
          />
        )}
        {/* <CTAWebinarComponent
          onWebinarRecording={onWebinarRecording}
          videoThumbnail={values.videoThumbnail}
          attachmentFile={values.webinarRecording}
          onVideoThumbnail={onVideoThumbnail}
          id={values.id}
          index={values.id}
          externalUrl={values.externalUrl}
          ctaValue={values.ctaTitle}
          urlType={values.urlType}
          value={values.url}
          isHide={true}
          onChange={handleChange}
          swappingOrder={values.swappingOrder}
        /> */}
       
         <QuillTextEditor
          label='RichText Editor'
          Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.'
          value={htmlContent}
          quillRef={editorRef}
          setHTMLContent={setHTMLContent}
        />
        <div className='webinar-card'>
          <div className='webinar-container'>
            {Speekervalues.speekers.map((data, index) => {
              return (
                <WebinarCard
                  key={data.id}
                  id={data.speakerId}
                  title={data.title}
                  index={index}
                  text='Speaker'
                  handleRemoveCard={handleRemoveSpeekerSection}
                >
                  <p className='speekertext'>Speaker</p>

                  <Attachment
                    value={data?.attachmentFile ? data?.attachmentFile : null}
                    id={data.id}
                    className='speaker-attachment'
                    onFileSelected={onFileSpeekerSelected}
                    fileType='image'
                    label='add attachment'
                  />

                  <NewInput
                    label='Speaker Name'
                    id='name'
                    type='text'
                    placeholder='Ex: Enter Speeker Name'
                    name='name'
                    onChange={(e) => OnhandleSpeeker(e, index)}
                    value={data.name}
                    // onChange={handleChange}
                    // errorText={error.speekername}
                  />

                  <TextArea
                    id='description'
                    label='Speaker profession'
                    placeholder='Enter Description'
                    rows='3'
                    type='text'
                    name='description'
                    onChange={(e) => OnhandleSpeeker(e, index)}
                    value={data.description}
                    // onChange={handleChange}
                    // errorText={error.speekerdescription}
                  />

                  <NewInput
                    label='Speaker’s Experience'
                    id='displayOrder'
                    placeholder='Enter display Order'
                    name='displayOrder'
                    onChange={(e) => OnhandleSpeeker(e, index)}
                    value={data.displayOrder}
                    type='text'
                    // onChange={OnhandleChange}
                  />
                </WebinarCard>
              );
            })}
            {!hideFields ? (
              <div className='webinar-host-but'>
                <Button
                  className='ml1'
                  onClick={handleAddSpeekerSection}
                  type='submit'
                  name='Add SPEAKER'
                  disabled={isAddButtonDisabled}
                />
              </div>
            ) : null}
          </div>
          <div className='webinar-container'>
            {hostValues.hosts.map((data, index) => {
              return (
                <WebinarCard
                  key={data.id}
                  title={data.title}
                  index={index}
                  id={data.speakerId}
                  text='Host'
                  handleRemoveCard={handleRemoveHostSection}
                >
                  <p className='speekertext'>Hosted By</p>
                  <Attachment
                    value={data.attachmentFile ? data.attachmentFile : null}
                    id={data.hostId}
                    onFileSelected={onFileHostSelected}
                    fileType='image'
                    label='add attachment'
                    className='speaker-attachment'
                    // label={`Add Banner ${index + 1}`}
                  />

                  <NewInput
                    label='Host Name'
                    id='name'
                    type='text'
                    placeholder='Ex: Enter Host Name'
                    name='name'
                    value={data.name}
                    onChange={(e) => OnhandleHost(e, index)}
                    // errorText={error.hostname}
                  />

                  <TextArea
                    id='designation'
                    label='Host profession'
                    placeholder='Enter Description'
                    rows='3'
                    type='text'
                    name='designation'
                    value={data.designation}
                    onChange={(e) => OnhandleHost(e, index)}
                    // errorText={error.hostdescription}
                  />

                  <NewInput
                    label='Host’s Experience'
                    id='displayOrder'
                    placeholder='Enter display Order'
                    name='displayOrder'
                    value={data.displayOrder}
                    onChange={(e) => OnhandleHost(e, index)}
                    type='text'
                  />
                </WebinarCard>
              );
            })}
            {!hideFields ? (
              <div className='webinar-host-but'>
                <Button
                  className='ml1'
                  onClick={handleAddWebinarSection}
                  type='submit'
                  name='Add HOST'
                  disabled={isAddButtonDisabled}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* ); */}
        <div className='mt1'>
          <Button
            isDisabled={pageLoading ? true : false}
            onClick={handleCreateWebinar}
            type='submit'
            name='Submit'
          />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateWebinar;
